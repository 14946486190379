import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import pic05 from '../assets/images/pic05.jpg'
import Five from '../components/Five'

const LeftSidebar = props => (
  <Layout>
    <Helmet>
      <title>Travelonux - Hacé crecer tu negocio</title>
      <meta name="description" content="Agencias de viajes, paginas web, motores, plataforma, tecnologia" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2 style={{margin: `0 0 0.2em 0`}}>Paquetes</h2>
          <p style={{margin: `0 0 0.2em 0`}}>
            Una solucion simple y de rapida implementacion para aumentar el ingreso de consultas para tus vendedores
          </p>
        </header>
        <div className="row gtr-150">
          <div className="col-4 col-12-medium">
            <section id="sidebar">
              <section>
                <Five />
              </section>
            </section>
          </div>
          <div className="col-8 col-12-medium imp-medium">
            <section id="content">
              <a href="#" className="image fit">
                <img src={pic05} alt="" />
              </a>
              <h3>FRONTEND</h3>
             <h4> Aspectos generales:</h4>
              <p>
              Además del diseño personalizado según los requerimientos de tu Agencia, 
              podrás incluir imágenes en alta calidad, videos, ajustar el formato de texto, 
              entre otras funcionalidades, gracias a nuestros Plugins de edición HTML 
              de fácil aplicación. El formulario de solicitud acompañará al usuario en todo 
              el scroll para que no pierda el interés en la consulta. ¡Más consultas, más ventas! 
              </p>
              <h4> Buscador:</h4>
              <p>
              A partir de un buscador de tres elementos los usuarios podrán encontrar  
              paquetes por destino, temporada y tipo de viaje. todos estos parámetros 
              precargados por la agencia, también se podrán listar todos los Paquetes. 

              </p>
              <h4> Vista de listado:</h4>
              <p>
              Cada paquete tendrá su imagen principal, titulo y iconos con la composicion del paquete.
              A cada uno lo podrás etiquetar con ofertas, descuentos,etc.. si así lo deseas.  
              Tus usuarios también podrán compartir los paquetes a través de las redes sociales. 
              </p>

              <h4> No te limites!</h4>
              <p>
              Con la galería de imágenes y el editor de HTML podrás mostrar todo lo que el usuario quiere ver para decidirse 
              a hacer una consulta. Invitalos a vivir la experiencia de compra en tu Agencia desde la web! 
              </p>

              <h4> Una buena experiencia</h4>
              <p>
              ¡Con los datos necesarios para que el usuario no rebote y consulte ya es suficiente! Obtené información básica 
              para que luego tus Vendedores hagan lo que saben hacer: ¡VENDER!
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default LeftSidebar
